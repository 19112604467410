const TokenKey = 'Admin-Token'
const RefreshTokenKey = 'Refresh-Token'
const Openid = 'Openid'
const Img = 'Img'
const Name = 'Name'
const IsBind = 'IsBind'
const DispName = 'DispName'
const Nav = 'Nav'
const NavIndex = 'Nav-index'
const ClinicId = 'ClinicId'
const ClinicName = 'ClinicName'
const ClinicList = 'ClinicList'
const IsRefresh = 'IsRefresh'
const IsClose = 'IsClose'
const IsDoctor = 'IsDoctor'

// localStorage方式
export function getToken() {
  return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return sessionStorage.removeItem(TokenKey)
}
// Refreshtoken (逻辑和token一样)
export function getRefreshToken() {
  return sessionStorage.getItem(RefreshTokenKey)
}

export function setRefreshToken(refreshtoken) {
  return sessionStorage.setItem(RefreshTokenKey, refreshtoken)
}

export function removeRefreshToken() {
  return sessionStorage.removeItem(RefreshTokenKey)
}

// openid (逻辑和token一样)
export function getOpenid() {
  return sessionStorage.getItem(Openid)
}

export function setOpenid(openid) {
  return sessionStorage.setItem(Openid, openid)
}

export function removeOpenid() {
  return sessionStorage.removeItem(Openid)
}

// 获取用户id
export function getImg () {
  return sessionStorage.getItem(Img)
}

export function setImg (img) {
  return sessionStorage.setItem(Img, img)
}

export function getName () {
  return sessionStorage.getItem(Name)
}

export function setName (name) {
  return sessionStorage.setItem(Name, name)
}

export function getIsBind () {
  return sessionStorage.getItem(IsBind)
}

export function setIsBind (isBind) {
  return sessionStorage.setItem(IsBind, isBind)
}

export function getDispName () {
  return sessionStorage.getItem(DispName)
}

export function setDispName (dispName) {
  return sessionStorage.setItem(DispName, dispName)
}

export function removeImg () {
  return sessionStorage.removeItem(Img)
}
// nav
export function getNav() {
  return sessionStorage.getItem(Nav)
}

export function setNav(nav) {
  return sessionStorage.setItem(Nav, nav)
}
// navIndex
export function getNavIndex() {
  return sessionStorage.getItem(NavIndex)
}

export function setNavIndex(navIndex) {
  return sessionStorage.setItem(NavIndex, navIndex)
}

export function removeNavIndex() {
  return sessionStorage.removeItem(NavIndex)
}

export function setClinicId(clinicId) {
  return sessionStorage.setItem(ClinicId, clinicId)
}

export function getClinicId() {
  return sessionStorage.getItem(ClinicId)
}

export function setClinicName(clinicName) {
  return sessionStorage.setItem(ClinicName, clinicName)
}

export function getClinicName() {
  return sessionStorage.getItem(ClinicName)
}

export function setClinicList(clinicList) {
  return sessionStorage.setItem(ClinicList, clinicList)
}

export function getClinicList() {
  return sessionStorage.getItem(ClinicList)
}

export function setIsRefresh(isRefresh) {
  return sessionStorage.setItem(IsRefresh, isRefresh)
}

export function getIsRefresh() {
  return sessionStorage.getItem(IsRefresh)
}

export function setIsClose(isClose) {
  return sessionStorage.setItem(IsClose, isClose)
}

export function getIsClose() {
  return sessionStorage.getItem(IsClose)
}

export function setIsDoctor(isDoctor) {
  return sessionStorage.setItem(IsDoctor, isDoctor)
}

export function getIsDoctor() {
  return sessionStorage.getItem(IsDoctor)
}