<template>
    <div class="nav_bottom" v-if="nav">
        <div class="my_bottom_tab">
            <div :class="['one',navIndex == '0'?'active':'']" @click="jumpTo('0')">
                <img v-if="navIndex == 0" src="@/assets/icon_index_active.png" alt="">
                <img v-else src="@/assets/icon_index.png" alt="">
                <span>工作台</span>
            </div>
            <!-- <div :class="['one',navIndex == '2'?'active':'']" v-if="isDoctor == '1'" @click="jumpTo('2')">
                <img v-if="navIndex == 2" src="@/assets/icon_doctor_active.png" alt="">
                <img v-else src="@/assets/icon_doctor.png" alt="">
                <span>就诊</span>
            </div> -->
            <div :class="['one',navIndex == '1'?'active':'']" @click="jumpTo('1')">
                <img v-if="navIndex == 1" src="@/assets/icon_my_active.png" alt="">
                <img v-else src="@/assets/icon_my.png" alt="">
                <span>我的</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        computed: {
            ...mapGetters([
                'nav',
                'navIndex',
                'isDoctor'
            ]),
        },
        data() {
            return {
                tabIndex: '0'
            }
        },
        mounted() {
            this.tabIndex = this.navIndex
            // this.$store.dispatch('changeNav', '0')
            console.log('isdoctor == ', this.isDoctor)
        },
        methods: {
            jumpTo(index){
                this.tabIndex = index
                this.$store.dispatch('changeNav', index)
                this.$store.dispatch('changeIsRefresh', '1')
                if(index == '0'){
                    this.$router.push({
                        path: '/'
                    })
                }else if(index == '1'){
                    this.$router.push({
                        path: '/my'
                    })
                }else if(index == '2'){
                    this.$router.push({
                        path: '/seePage'
                    })
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/styles/flex.scss';
    .my_bottom_tab{
        @include lc-row-center;
        background: #fff;
        width: 100%;
        height: 54px;
        
        border-top: 1px solid #d8d8d8;
        .one{
            width: calc(100%/2);
            @include lc-row-column-center();
            font-size: 12px;
            img{
                width: 34px;
                height: 34px;
            }
            &.active{
                color:#3D4FBD;
            }
        }
    }
    .nav_bottom{
        height: 54px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
    }
</style>
