import request from '@/utils/request'
import interfaceHandle from '@/utils/index'


export function login(data) {
  return request({
    url: '',
    method: 'post',
    data: interfaceHandle('wxuserlogin', data)
  })
}


export function bindaccount(data) {
  return request({
    url: '',
    method: 'post',
    data: interfaceHandle('wxbindaccount', data, true)
  })
}