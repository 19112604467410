import { login } from '@/api/login'
// import Layout from '@/views/layout/Layout'
import { getCode } from '@/utils'
import { setName,getName, getToken, setToken, getRefreshToken, setRefreshToken,
  getOpenid, setOpenid,getClinicId, setNavIndex,setNav,getClinicName } from '@/utils/auth'
import {
  getClinicList,
  getDispName,
  getImg, getIsDoctor,
  setClinicId, setClinicList,
  setClinicName,
  setDispName,
  setImg,
  setIsBind, setIsClose, setIsDoctor,
  setIsRefresh
} from "../../utils/auth";

const user = {
  state: {
    token: getToken(),
    refreshtoken: getRefreshToken(),
    openid: getOpenid(),
    isbind: '0',
    username: getName(),
    dispname: getDispName(),
    img: getImg(),
    nav: true,
    navIndex: '0',
    isrefresh: '0',
    isclose: '0',
    isdoctor: getIsDoctor(),
    clinicid: getClinicId(),
    clinicname: getClinicName(),
    cliniclist: getClinicList(),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_REFRESH_TOKEN: (state, refreshtoken) => {
      state.refreshtoken = refreshtoken
    },
    SET_OPENID: (state, openid) => {
      state.openid = openid
    },
    SET_ISBIND: (state, isbind) => {
      state.isbind = isbind
    },
    SET_NAME: (state, username) => {
      state.username = username
    },
    SET_NAV: (state, nav) => {
      state.nav = nav
    },
    SET_NAVINDEX: (state, navIndex) => {
      state.navIndex = navIndex
    },
    SET_CLINICID: (state, clinicid) => {
      state.clinicid = clinicid
    },
    SET_CLINICNAME: (state, clinicname) => {
      state.clinicname = clinicname
    },
    SET_IMG: (state, img) => {
      state.img = img
    },
    SET_DISPNAME: (state, dispName) => {
      state.dispname = dispName
    },
    SET_CLINICLIST: (state, clinicList) => {
      state.cliniclist = clinicList
    },
    SET_ISREFRESH: (state, isrefresh) => {
      state.isrefresh = isrefresh
    },
    SET_ISCLOSE: (state, isclose) => {
      state.isclose = isclose
    },
    SET_ISDOCTOR: (state, isdoctor) => {
      state.isdoctor = isdoctor
    },
  },

  actions: {
    // 登录
    Login({ commit }) {
      var code = getCode('code') || getCode('code', true)
      var data = {
        code: code
      }
      const isDevelopment = process.env.NODE_ENV === 'development'
      if(isDevelopment){
        // setOpenid('obpGr1I2T2sFMGxsQ2QBXkuA4Eta')
        setOpenid('owCUy6qq8jNzzmanyCXqxPIPNQ8U')
        commit('SET_NAME', "bzp")
        // commit('SET_CLINICID', "2")
        // commit('SET_CLINICNAME', "颐中诊所")
        // commit('SET_IMG', "http://thirdwx.qlogo.cn/mmopen/FMajU52WvbGudzuADGAHcypl79fXh2Pbz001G219ZTrl4Il9dekm8uzJuwFQnMMsTU7j4GoJot5OT3O5XMv2Xw/132")
        commit('SET_DISPNAME', "边志平")
        commit('SET_CLINICLIST', JSON.stringify([{'clinicid':'28','clinicname':'（荔湾店）'},{'clinicid':'2','clinicname':'颐中中医（天河店）'},{'clinicid':'24','clinicname':'测试中医诊所'}]))
        commit('SET_OPENID', 'owCUy6qq8jNzzmanyCXqxPIPNQ8U')
        setToken('resp_datatoken')
        setRefreshToken("resp_datarefreshtoken")
        commit('SET_TOKEN', 'resp_datatoken')
        commit('SET_REFRESH_TOKEN', 'resp_datarefreshtoken')
        commit('SET_ISDOCTOR', '1')
      }else{
        return new Promise((resolve, reject) => {
          login(data).then(response => {
            const resp_data = response.response_body
            setToken(resp_data.token)
            console.log(response,'response-----')
            setRefreshToken(resp_data.refreshtoken)
            setOpenid(resp_data.openid)
            setName(resp_data.username)

            commit('SET_OPENID', resp_data.openid)
            commit('SET_TOKEN', resp_data.token)
            commit('SET_REFRESH_TOKEN', resp_data.refreshtoken)
            commit('SET_ISBIND', resp_data.isbind)
            commit('SET_NAME', resp_data.username)
            commit('SET_IMG', resp_data.img)
            setIsDoctor(resp_data.isdoctor)
            commit('SET_ISDOCTOR', resp_data.isdoctor)
            setImg(resp_data.img)
            commit('SET_DISPNAME', resp_data.dispname)
            setDispName(resp_data.dispname)
            // setClinicList(resp_data.cliniclist)
            const clinicList = resp_data.cliniclist;
            if(clinicList != null && clinicList.length > 0) {
              if(clinicList.length == 1) {
                setClinicId(clinicList[0].clinicid)
                setClinicName(clinicList[0].clinicname)
                commit('SET_CLINICID', clinicList[0].clinicid)
                commit('SET_CLINICNAME', clinicList[0].clinicname)
              } else {
                let cliniclist = JSON.stringify(resp_data.cliniclist);
                setClinicList(cliniclist)
                commit('SET_CLINICLIST', cliniclist)
              }
            }
            console.log(resp_data)
            resolve()
          }).catch(error => {
            console.log(error)
            reject(error)
          })
        })
      }
    },
    changeNav({commit}, navIndex){
      commit('SET_NAVINDEX',navIndex)
      setNavIndex(navIndex)
    },
    hideOrShowNav({commit}, nav){
      commit('SET_NAV',nav)
      setNav(nav)
    },
    changeClinic({commit}, clinicId) {
      commit('SET_CLINICID', clinicId)
      setClinicId(clinicId)
    },
    changeClinicName({commit}, clinicName) {
      commit('SET_CLINICNAME', clinicName)
      setClinicName(clinicName)
    },
    changeBind({commit}, isBind) {
      commit('SET_ISBIND', isBind)
      setIsBind(isBind)
    },
    changeIsRefresh({commit}, isRefresh) {
      commit('SET_ISREFRESH', isRefresh)
      setIsRefresh(isRefresh)
    },
    changeIsClose({commit}, isClose) {
      commit('SET_ISCLOSE', isClose)
      setIsClose(isClose)
    }
  }
}

export default user
