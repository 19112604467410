const getters = {


  token: state => state.user.token,
  refreshtoken: state => state.user.refreshtoken,
  openid: state => state.user.openid,
  username: state => state.user.username,
  dispname: state => state.user.dispname,
  img: state => state.user.img,
  isbind: state => state.user.isbind,
  navIndex: state => state.user.navIndex,
  nav: state => state.user.nav,
  clinicid: state => state.user.clinicid,
  clinicname: state => state.user.clinicname,
  cliniclist: state => state.user.cliniclist,
  isrefresh: state => state.user.isrefresh,
  isDoctor: state => state.user.isdoctor,
  isclose: state => state.user.isclose,
}
export default getters
