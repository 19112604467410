import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getToken,getRefreshToken, setToken, setRefreshToken } from '@/utils/auth'

import { Toast } from 'vant'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 30000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers['X-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    
    const res = response.data
    
    if (res.response_head.retinfo.retcode !== '0') {
      // Message({
      //   message: res.response_head.retmsg,
      //   type: 'error',
      //   duration: 5 * 1000
      // })

        // 暂时删除
        Toast({
            message: res.response_head.retinfo.retmsg,
            duration: 3000
        })
        if(res.response_head.retinfo.retcode == '1001'
            || res.response_head.retinfo.retcode == '1002'
            || res.response_head.retinfo.retcode == '1003'
            || res.response_head.retinfo.retcode == '1004'
            || res.response_head.retinfo.retcode == '4001') {
            let second = 3;
            const timer = setInterval(() => {
                second--;
                if (!second) {
                    clearInterval(timer);
                    console.log("--token过期或失效，重新登录----")
                    //去掉缓存中的token
                    setToken("")
                    let pageUrl = window.location.href
                    console.log(pageUrl, '--pageUrl')
                    let urlParam = '';   //页面参数
                    let beforeUrl = "";
                    if(pageUrl.indexOf("?") > 0) {
                        beforeUrl = pageUrl.substring(0, pageUrl.indexOf("?"));   //页面主地址（参数之前地址）
                        urlParam = pageUrl.substring(pageUrl.indexOf("?") + 1);
                    }
                    if(beforeUrl == '') {
                        beforeUrl = pageUrl;
                    }
                    let nextUrl = "";

                    let arr = new Array();
                    if (urlParam != "") {
                        let urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
                        for (let i = 0; i < urlParamArr.length; i++) {
                            let paramArr = urlParamArr[i].split("="); //将参数键，值拆开
                            //删除code参数
                            if (paramArr[0] != "code") {
                                arr.push(urlParamArr[i]);
                            }
                        }
                    }
                    if (arr.length > 0) {
                        nextUrl = "?" + arr.join("&");
                    }
                    let url = encodeURIComponent(beforeUrl + nextUrl);
                    let appid = process.env.VUE_APP_ID
                    let urlWX = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid +"&redirect_uri="+ url +"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
                    window.location.href = urlWX
                }
            }, 1000);
        }

      return Promise.reject(res || 'error')
    } else {
      var infCode = res.response_head.inf_code;
      if(infCode != 'wxuserlogin') {
        // console.log('-------------63633663636')
        var tokenNew = res.response_head.token
        var refreshtokenNew = res.response_head.refreshtoken

        if(tokenNew != undefined && tokenNew != getToken()){
          setToken(tokenNew)
        }
        if(refreshtokenNew != undefined && refreshtokenNew != getRefreshToken()){
          setRefreshToken(refreshtokenNew)
        }
      }
      
      return response.data
    }
  },
  error => {
    console.log('error' + error) // for debug
    Toast({
      message: error,
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service
