import { wxjsapiauth } from '@/api/medical'
import { getOpenid } from '@/utils/auth'
import wx from 'weixin-js-sdk'

export function initWxJssdkFilter () {
  let jsApiList = [
    'closeWindow',
    'scanQRCode',
    'chooseWXPay',
    'hideMenuItems', 'hideAllNonBaseMenuItem'
  ]
  var url = window.location.href
  let data = {
    openid: getOpenid(),
    url: url,
  }
  wxjsapiauth(data).then(response => {
    console.log(response, '微信配置---', url)
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: response.response_body.appid, // 必填，公众号的唯一标识
      timestamp: response.response_body.timestamp, // 必填，生成签名的时间戳
      nonceStr: response.response_body.noncestr, // 必填，生成签名的随机串
      signature: response.response_body.signature, // 必填，签名
      url: response.response_body.url,
      jsApiList: jsApiList // 必填，需要使用的JS接口列表
    })
    wx.ready(function () {
      console.log('执行了----------')
      wx.closeWindow();
    })
  })
}
